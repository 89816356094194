import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { bcApi } from '../../helpers/bigcommerce';

import AddressCard from '../../components/molecules/AddressCard/AddressCard';
import AddressForm from '../../components/molecules/AddressForm/AddressForm';
import AccountPageWrapper from '../../components/organisms/AccountPageWrapper/AccountPageWrapper';

import { useQuery } from '@tanstack/react-query';
import * as styles from './addresses.module.css';

const Addresses = ({ customerId }) => {
  const [active, setActive] = useState(null);

  const { data: addresses, isLoading, refetch } = useQuery(
    ['addresses', customerId],
    async () => await bcApi(`customers/addresses?customer_id:in=${customerId}`)
  );

  const addAddress = () => setActive({});

  const editAddress = address => setActive(address);

  const cancelUpdate = () => setActive(null);

  const saveAddress = address => {
    if (isEmpty(address)) {
      return;
    }
    if (address.id) {
      // update address
      bcApi(`customers/addresses`, 'PUT', [address]).then(({ status }) => {
        if (status === 200) {
          refetch();
          setActive(false);
        }
      });
    } else {
      bcApi(`customers/addresses`, 'POST', [
        { ...address, customer_id: customerId }
      ]).then(({ response, status }) => {
        if (status === 200) {
          refetch();
          setActive(false);
        }
      });
    }
  };

  const removeAddress = addressId => {
    // delete address
    const endpoint = `customers/addresses?id:in=${addressId}`;
    bcApi(endpoint, 'DELETE').then(response => {
      if (response.status === 204) {
        refetch();
      }
    });
  };

  return (
    <>
      <h1 className={`center-title ${styles.pageTitle}`}>Addresses</h1>
      {!isLoading && addresses && addresses.response.data.length === 0 && (
        <div className={styles.pageEmpty}>You have no saved addresses</div>
      )}

      {!isLoading && !active && (
        <div className={styles.container}>
          {addresses?.response?.data?.map((address, addressIndex) => (
            <AddressCard
              address={address}
              key={addressIndex}
              onEdit={() => editAddress(address)}
              onDelete={() => removeAddress(address.id)}
            />
          ))}

          <AddressCard onAddAddress={addAddress} />
        </div>
      )}

      <AddressForm
        address={active}
        visible={!!active}
        isSaving={isLoading}
        onSubmit={saveAddress}
        onCancel={cancelUpdate}
      />

      {isLoading && <span>Fetching your stored addresses...</span>}
    </>
  );
};

const AddressesOutput = () => (
  <AccountPageWrapper metaTitle="Pet Chemist Online - Your Address Book" title="Addresses">
    <Addresses />
  </AccountPageWrapper>
);

export default AddressesOutput;
