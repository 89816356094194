import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ConfirmDialog from '../../atoms/ConfirmDialog/ConfirmDialog';
import Icon from '../../atoms/Icon/Icon';
import * as pcStyles from './AddressCard.module.css';
import * as mpsStyles from './AddressCard.mps.module.css';
import { storeStyle, isMadPawsStore } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const AddressCard = ({
  address = {},
  onEdit = () => null,
  onDelete = () => null,
  onAddAddress = () => null
}) => {
  const handleClickEdit = () => {
    if (typeof onEdit === 'function') {
      onEdit();
    }
  };

  const handleClickRemove = () => {
    if (typeof onDelete === 'function') {
      onDelete();
    }
  };

  if (isEmpty(address)) {
    return (
      <div
        className={`${styles.rootEmpty} shadow`}
        role="button"
        tabIndex={0}
        onClick={onAddAddress}
        onKeyDown={onAddAddress}
      >
        <div className={styles.emptyText}>
          <Icon symbol={isMadPawsStore() ? 'plus-mps-alt' : 'plus-alt'} />
          <p>New address</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.addressContent}>
        <div className={styles.fullName}>
          {address.first_name} {address.last_name}
        </div>
        {address.phone && <div className={styles.addressProperty}>{address.phone}</div>}
        {address.company && <div className={styles.addressProperty}>{address.company}</div>}
        <div className={styles.addressProperty}>{address.address1}</div>
        {address.address2 && <div className={styles.addressProperty}>{address.address2}</div>}
        <div className={styles.addressProperty}>
          {address.city}, {address.state_or_province}, {address.postal_code}
        </div>
        <div className={styles.addressProperty}>{address.country}</div>
      </div>

      <div className={styles.addressAction}>
        <span
          role="button"
          data-button
          tabIndex={0}
          className="icon-wrap"
          onClick={handleClickEdit}
          onKeyDown={handleClickEdit}
        >
          <Icon symbol={isMadPawsStore() ? 'pencil-mps' : 'pencil'} />
        </span>
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex  */}
        <ConfirmDialog
          title="Are you sure?"
          message="Are you sure you want to remove this address? It will not be recoverable after you proceed."
          onOk={handleClickRemove}
        >
          <span tabIndex={0} data-button className="icon-wrap">
            <Icon symbol={isMadPawsStore() ? 'trash-mps' : 'trash'} />
          </span>
        </ConfirmDialog>
        {/* eslint-disable jsx-a11y/no-noninteractive-tabindex  */}
      </div>
    </div>
  );
};

AddressCard.propTypes = {
  address: PropTypes.shape({}),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddAddress: PropTypes.func
};

export default AddressCard;
